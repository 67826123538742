// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["__sentryRewritesTunnelPath__"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"c96dc3410f40c848c576f00fec7d7ef5ef48f944"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "/espresso-assets";import * as Sentry from '@sentry/nextjs';
import { sentrySharedOptions } from 'sentry-shared';

Sentry.init({
  ...sentrySharedOptions,
});
